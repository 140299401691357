<template>
  <div>
    <div class="search">
      <div class="title">余额流水列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="真实姓名:">
          <el-input v-model="params.trueName"></el-input>
        </el-form-item>
        <el-form-item label="昵称:">
          <el-input v-model="params.nickName"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="是否店长:">
          <el-select clearable v-model="params.isShop" placeholder="请选择">
            <el-option
              v-for="item in isShopOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
          <el-button type="primary" @click="createForm">导出报表</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-checkbox-group v-model="selectIds" size="mini" > -->

    <el-table
      :row-key="rowKey"
      :data="tableData"
      border
      style="width: 100%"
      align="center"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        :reserve-selection="true"
        align="center"
      >
      </el-table-column>
      <!-- <el-table-column  width="105"   align="center"  :reserve-selection="true"> 
        <template slot-scope="scope">
            <div>
                  <el-checkbox v-model="scope.checked"   :checked="scope.checked" @change="handleSelectionChange($event,scope.row)">备选项</el-checkbox>
            </div>
        </template>
        </el-table-column> -->
      <el-table-column prop="userId" width="100" label="ID" align="center">
      </el-table-column>
      <el-table-column prop="times" label=" 统计时间" align="center">
      </el-table-column>
      <el-table-column prop="trueName" label="姓名" align="center">
      </el-table-column>
      <el-table-column prop="isShop" label="是否店长" align="center">
      </el-table-column>
      <el-table-column prop="shopName" label="店长名称" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="手机号" align="center">
      </el-table-column>
      <el-table-column prop="beingFee" label="期初金额" align="center">
      </el-table-column>
      <el-table-column prop="endFee" label="期末余额/待提现" align="center">
      </el-table-column>
      <el-table-column prop="bb" label="充值" width="120" align="center">
      </el-table-column>
      <el-table-column prop="cc" label="万小店收益" width="120" align="center">
      </el-table-column>
      <el-table-column prop="jj" label="待收益" width="120" align="center">
      </el-table-column>
      <el-table-column prop="ee" label="管理员增加" width="120" align="center">
      </el-table-column>
      <el-table-column prop="ff" label="退款" width="120" align="center">
      </el-table-column>
      <el-table-column
        prop="hh"
        label="拒绝提现返回"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="ii"
        label="精选商城退款"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="totalGetFee" label="总收入金额" align="center">
      </el-table-column>
      <el-table-column prop="aa" label="订单消费" width="120" align="center">
      </el-table-column>
      <el-table-column prop="dd" label="提现" width="120" align="center">
      </el-table-column>
      <el-table-column prop="gg" label="管理员减少" width="120" align="center">
      </el-table-column>
      <!-- <el-table-column
        label="头像 "
        width="180"
        align="center">
        <template slot-scope="scope">
            <el-image 
                style="width: 70px; height: 70px"
                :src="scope.row.headImg" 
                :preview-src-list="[scope.row.headImg]">
            </el-image>
        </template>
        </el-table-column> -->
      <el-table-column
        prop="totalOutFee"
        label="总支出余额"
        width="200"
        align="center"
      >
      </el-table-column>

      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button
            type="primary"
            v-if="scope.row.isShop"
            size="mini"
            @click="toDetails(scope.row)"
            >分销订单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- </el-checkbox-group> -->
    <div class="block">
      <!-- <div>
        <el-button type="primary" @click="allSetLabel">批量修改标签</el-button>
      </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getBalList, InpotAPI } from "../../api/Statistics.js";
export default {
  data() {
    return {
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        nickName: "",
        phone: "",
        beginTime: "",
        endTime: "",
        trueName: "",
        isShop: -2,
      },
      tableData: [],
      isShopOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      date: null,
      selectIds: "",
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    toDetails(row) {
      this.$router.push("/balanceJournalDetails?userId=" + row.userId);
    },
    rowKey(row) {
      return row.userId;
    },
    handleSelectionChange(e) {
      this.selectIds = e.map((item) => item.userId).join(",");
      console.log(this.selectIds);
    },
    async createForm() {
      if (!this.selectIds) {
        return this.$message.warning("请选择数据");
      }

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let date = +new Date();
      const { data } = await InpotAPI({
        ...this.params,
        userIds: this.selectIds,
      });
      let url = window.URL.createObjectURL(data); //转换文件流未url
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `余额流水报表_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      loading.close();
    },
    async getList() {
      this.params.beginTime = this.date ? this.date[0] : "";
      this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await getBalList(this.params);
      if (data.code == 0) {
        // this.$nextTick(()=>{

        // })
        // const arr =  data.list.map(ele => {

        //     if( this.selectIds.includes(ele.userId)){
        //         this.$set(ele,"checked",true)
        //         // ele.checked = true
        //     }else{
        //         this.$set(ele,"checked",false)
        //     }
        //     return ele
        // });
        // console.log(data.list)
        this.tableData = data.list;

        this.total = data.pagination.total;
      } else {
        this.$message.warning(data.msg);
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.params.trueName = "";

      this.date = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>